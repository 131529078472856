<template>
  <div class="content md no-padding flex-box vertical">
    <ActivityForm ref="addForm" :initial="initial" class="add-form-box"></ActivityForm>
    <a-space class="footer">
      <a-button @click="handleCancel">取消</a-button>
      <a-button type="primary" @click="handleConfirm">保存</a-button>
    </a-space>
  </div>
</template>

<script>
import ActivityForm from "./components/ActivityForm.vue";

export default {
  name: "ActivityAdd",
  components: {
    ActivityForm,
  },
  data() {
    return {
      initial: null,
    };
  },
  computed: {
    id() {
      return this.$route.query.id;
    },
  },
  created() {
    this.getInitial();
  },
  methods: {
    getInitial() {
      // 复制
      if (this.id) {
        this.$axios({
          url: `/admin/volunteer-activity/${this.id}`,
          method: "GET",
          params: {
            expand: "activityType,userRole,joinGroup",
          },
        }).then((res) => {
          this.initial = res;
          delete this.initial.id;
        });
      }
    },
    handleCancel() {
      this.$router.back();
    },
    handleConfirm() {
      this.$refs.addForm.validate().then((form) => {
        this.$axios({
          url: "/admin/volunteer-activity",
          method: "POST",
          data: form,
        }).then(() => {
          this.$message.success("添加成功");
          this.$router.back();
        });
      });
    },
  },
};
</script>

<style lang="less" scoped>
.footer {
  width: 100%;
}
</style>
